import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { getAllStores } from './actions/main';
import VisibleMain from './containers/VisibleMain';
import { changeTab, tabs } from './actions/nav';
import Amplify, { Hub, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { getAllFreeShippingBarStores } from './actions/freeShippingBar';
import { setAdminActionId } from './actions/adminActions';
import { AuthProvider } from '@descope/react-sdk';
Amplify.configure(awsconfig);

const store = configureStore();

export const host = 'api.zoorix.com';
export const stickyAtcHost = 'sticky-atc-api.zoorix.com';
export const newStickyAtcHost = process.env.NODE_ENV === 'production' ? 'satc-2.zoorix.com' : 'localhost:3004';
export const schema = process.env.NODE_ENV === 'production' ? 'https' : 'http';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider projectId="P2cxhOi9fwua6LDXqreTZn1n5RsC">
      <Provider store={store}>
        <AppProvider i18n={enTranslations}>
          <VisibleMain />
        </AppProvider>
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

let params = new URLSearchParams(window.location.search);
const tab = params.get('tab') || 'upsell';
store.dispatch(changeTab(tabs.indexOf(tabs.find((t) => t.id === tab))));
if (tab === 'admin-actions') {
  store.dispatch(setAdminActionId(params.get('action_id')));
}
Hub.listen('auth', async (data) => {
  if (data.payload.event === 'signIn') {
    const token = data.payload.data.signInUserSession.accessToken.jwtToken;
    store.dispatch(getAllStores(token));
    // store.dispatch(getAllStickyAtcStores(token));
    store.dispatch(getAllFreeShippingBarStores(token));
  }
});
Auth.currentAuthenticatedUser().then(
  (r) => {
    const token = r.signInUserSession.accessToken.jwtToken;
    store.dispatch(getAllStores(token));
    store.dispatch(getAllFreeShippingBarStores(token));
  },
  (r) => console.log('user not logged in', r)
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
